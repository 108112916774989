<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList" @clearSearch="clearSearch">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.order_no" size="small" clearable placeholder="订单号">
				</el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.device_no" size="small" clearable placeholder="消费设备">
				</el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-date-picker v-model="searchFormData.dates" clearable type="daterange" format="yyyy-MM-dd"
					value-format="yyyy-MM-dd" start-placeholder="开始时期" end-placeholder="结束时期" size="small">
				</el-date-picker>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="order_no" title="订单号" align="center" />
			<vxe-table-column slot="table-item" field="device_no" title="消费设备" align="center" />
			<vxe-table-column slot="table-item" field="electric" title="充电电量（度）" align="center" />
			<vxe-table-column slot="table-item" field="pay_price" title="订单金额（元）" align="center" />
			<vxe-table-column slot="table-item" field="ele_price" title="电费（元）" align="center" />
			<vxe-table-column slot="table-item" field="service_price" title="服务费（元）" align="center" />
			<vxe-table-column slot="table-item" field="occupy_price" title="占用费（元）" align="center" />
			<vxe-table-column slot="table-item" field="type_text" title="订单类型" align="center" />
			<vxe-table-column slot="table-item" field="group_name" title="订单起止时间" align="center">
				<template v-slot="{row}">
					{{ (row.create_time||'')+"~"+(row.end_time||'') }}
				</template>
			</vxe-table-column>
		</Tables>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'
	export default {
		name: 'report-group-settle',
		components: {
			Tables,upload
		},
		data() {
			return {
				// 表格信息
				tableName: '结算明细',
				isLoading: false,
				totalPage: 0,
				searchFormData: {},
				List: [],
			}
		},
		computed: {
		},
		mounted() {
			//this.getList();
		},
		activated() {

		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					id: this.$route.query.id,
					group_id: this.$route.query.group_id,
					btime: this.$route.query.btime,
					etime: this.$route.query.etime,
					min: this.$route.query.min,
					max: this.$route.query.max,
					min_other: this.$route.query.min_other,
					max_other: this.$route.query.max_other,
					min_nh: this.$route.query.min_nh,
					max_nh: this.$route.query.max_nh,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Report.GroupShare.settleview(params);
				this.List = res.data;
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.editDialogFormData = {}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
</style>
